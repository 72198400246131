<template>
  <main>
    <sqr-hero
      short="documents"
      :title-raw="$route.query.title"
      :subtitle-raw="$route.query.subtitle"
      is-primary
      is-bold
      :is-loading="loading"
      is-fluid
    />
    <sqr-error :error="loadError" />
    <documents :bid="bid" :folder="folder"/>
  </main>
</template>


<script>
import SqrFilesUploader from '@/sqrd/SqrFilesUploader';
import Documents from '@/components/Documents';

export default {
  name: 'DocumentsView',
  components: { SqrFilesUploader, Documents },
  props: {
    bid: String,
    folder: String
  },
  metaInfo() {
    return {
      title: [this.$route.query.title, this.$t('documents_title')]
        .join(' ')
        .trim()
    };
  }
};
</script>
